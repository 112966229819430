


export default function Contact (){

    return (

        <div>
            <h1>This is the contant page.</h1>
        </div>

    )
}

import './App.css';
import Index from './components/home';

function App() {
  return (
    <>
    <Index />
    </>
  );
}

export default App;
